import React from 'react';

import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { request } from '@travauxlib/shared/src/utils/request';
import { baseImgixUrl } from '@travauxlib/shared/src/utils/urls';

import { ListCard } from './components/ListCard';
import './general.css';
import { BoardData, Sale } from './type';
import { displayMoney } from './utils/displayMoney';

import { baseUrl } from '../../config';

export const General: React.FC = () => {
  const { data } = useQuery<BoardData>({
    queryKey: ['MARIO'],
    queryFn: () => request(`${baseUrl}/data/super-mario`),
  });

  if (!data?.classement || !data?.general) {
    return <Loader size="lg" />;
  }

  const { classement, general } = data;
  const now = dayjs();
  const isAprilsFools = now.get('date') === 1 && now.get('month') === 3;
  const is20August = now.get('date') === 20 && now.get('month') === 7;
  const currentDate = new Date();

  const formattedDate = currentDate.toLocaleDateString('fr-FR', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const actualClassement = isAprilsFools
    ? classement.map((sale: Sale) => ({
        ...sale,
        first_name: 'Alexandre Surin',
        photo:
          'https://travauxlib-espace-pro-prod.s3.amazonaws.com/employees/person/63cfbe98-4308-4f71-8c41-f88b927961d0/picture/Official?auto=format&fit=crop&crop=top&h=300&w=220',
      }))
    : is20August
      ? classement.map((sale: Sale) => ({
          ...sale,
          first_name: 'Theau Antoine',
          photo:
            'https://images.hemea.com/employees/person/97c28f40-c49a-4a56-9e2d-cd11488af4ed/picture/Official-8iNui.png?auto=format&fit=crop&crop=top&h=300&w=220',
        }))
      : classement;

  return (
    <div className="p-xl">
      <div className="flex items-center justify-between">
        <img
          src={`${baseImgixUrl}/static/logo-hemea-black-v2.png?h=26`}
          srcSet={`${baseImgixUrl}/static/logo-hemea-black-v2.png?h=52 2x`}
          alt="hemea"
          className="mb-md"
        />
        <div>{formattedDate}</div>
      </div>
      <div className="flex flex-col sm-desktop:flex-row justify-around gap-xs">
        <ListCard general={general} />
        <div className="flex w-full">
          <Card className="w-full">
            <h2 className="text-h2 text-primary">GMV et revenus CDP</h2>
            <div className="mt-md flex flex-col gap-xs">
              {actualClassement.map((sale, index) => (
                <div
                  className="flex items-center h-xxl bg-neutral-100 rounded-xxs pl-md first:bg-warning-100 [&:nth-child(2)]:bg-neutral-300 [&:nth-child(3)]:bg-primarylight"
                  key={sale.photo}
                >
                  <div className="rank font-bold">{index + 1}.</div>
                  <div className="ml-md flex flex-1 justify-start label repo">
                    {sale.first_name}
                  </div>
                  <div className="amount-wrapper flex flex-1 justify-end">
                    <span
                      className={classNames('font-bold', {
                        'text-success-800': sale.gmv > 0,
                      })}
                    >
                      {displayMoney(sale.gmv)}
                    </span>
                  </div>
                  <div className="amount-wrapper flex flex-1 justify-end">
                    <span
                      className={classNames('font-bold', {
                        'text-success-800': sale.revenus > 0,
                      })}
                    >
                      {displayMoney(sale.revenus)}
                    </span>
                  </div>
                  <div className="flex flex-1 justify-end h-xxl w-xxl rounded-xxs">
                    <div className="relative">
                      <img className="h-xxl w-xxl object-cover rounded-xxs" src={sale.photo} />
                      {index === 0 && (
                        <div className="absolute crown -top-1/2 left-0 w-xxl h-xxl" />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
