import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { request } from '@travauxlib/shared/src/utils/request';

import { googleClientId } from './config';
import { baseUrl } from './config';
import { Zgeg } from './features/Baby/Zgeg';
import { Dev } from './features/Dev';
import { General } from './features/General/General';
import './index.css';
import { YoloSlack } from './features/YoloSlack/YoloSlack';
import { LoginButton, LogoutButton } from './loginButton';

import '@fontsource/noto-sans/latin.css';

const useLoggedIn = (): any => {
  const { data, isLoading } = useQuery({
    queryKey: ['AUTH'],
    queryFn: () => request(`${baseUrl}/admin-check`),
    staleTime: Infinity,
  });

  return { loggedIn: data?.loggedIn || false, isLoading };
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchInterval: 1000 * 30 },
  },
});

const Content = (): React.ReactElement => {
  const { loggedIn, isLoading } = useLoggedIn();

  if (isLoading) {
    return <Loader size="lg" />;
  }

  if (loggedIn) {
    return (
      <GoogleOAuthProvider clientId={googleClientId}>
        <Routes>
          <Route path="logout" element={<LogoutButton />} />
          <Route path="zgeg" element={<Zgeg />} />
          <Route path="general" element={<General />} />
          <Route path="dev" element={<Dev />} />
          <Route path="yolo-slack" element={<YoloSlack />} />
          <Route path="*" element={<Navigate replace to="general" />} />
        </Routes>
      </GoogleOAuthProvider>
    );
  }

  return (
    <div className="h-[100vh] flex items-center justify-center">
      <LoginButton />
    </div>
  );
};

const App = (): React.ReactElement => (
  <GoogleOAuthProvider clientId={googleClientId}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Content />
      </QueryClientProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

const root = createRoot(document.getElementById('root')!);
root.render(<App />);
