import classNames from 'classnames';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
type Props = {
  title: string;
  subTitle?: string | React.ReactElement;
  secondSubTitle?: string | React.ReactElement;
  children: React.ReactNode;
  timeData?: 'month' | 'week';
};

const getTraduction = (timeData: 'month' | 'week'): string =>
  timeData === 'month' ? 'Mois' : 'Semaine';
export const DataCard: React.FC<Props> = ({
  title,
  children,
  timeData,
  subTitle,
  secondSubTitle,
}) => (
  <Card className=" w-full sm-desktop:w-[15rem] md-desktop:w-[18rem] lg-desktop:w-[25rem]">
    {timeData && (
      <div className="flex justify-end">
        <Tag
          size="md"
          variant={timeData === 'week' ? 'warning' : 'info'}
          label={getTraduction(timeData)}
        />
      </div>
    )}
    <div className="flex flex-col justify-center items-center text-center">
      <div
        className={classNames('text-h3 lg-desktop:text-h2 mb-md font-bold', {
          'md-desktop:mt-md': timeData,
          'md-desktop:mt-xxl': !timeData,
        })}
      >
        {title}
      </div>
      {subTitle && (
        <div
          className={classNames('text-b1 text-neutral-700', {
            'mb-md': !secondSubTitle,
          })}
        >
          {subTitle}
        </div>
      )}
      {secondSubTitle && <div className="text-b1 text-neutral-700 mb-md">{secondSubTitle}</div>}
      <div>{children}</div>
    </div>
  </Card>
);
